@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url(./assets/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'),
    url(./assets/fonts/Roboto/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'),
    url(./assets/fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}

html {
  overflow-y: scroll;
}

@supports (scrollbar-gutter: stable) {
  html {
    overflow-y: auto;
    scrollbar-gutter: stable;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f7f7;
}

p,
h1,
ul,
li {
  margin: 0px;
  padding: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 456px;
  margin: 0 auto;
}

.videoContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.videoBottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 4px;
}

.videoContainer {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.video {
  display: flex;
}

.videoName {
  margin-top: 4px;
  font-size: 14px;
}

.videoDate {
  font-size: 14px;
}

.videoThumb {
  min-width: 180px;
  max-width: 180px;
  min-height: 101px;
  max-height: 101px;
  margin-right: 16px;
}

.videoLink {
  color: #1d59c7;
  cursor: pointer;
  font-size: 14px;
}

.videoPreview {
  color: #6446f0;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
}

.buttons {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
}

.button {
  flex: 1;
  background-color: #1d59c7;
  color: #ffffff;
  height: 34px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 15px;
  font-family: Roboto-Medium, Arial;
}

.buttonActive {
  flex: 1;
  background-color: #438cda;
  color: #ffffff;
  height: 34px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 15px;
  font-family: Roboto-Medium, Arial;
}

.uploadPlaceholder {
  height: 504px;
  width: 456px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadButton {
  flex: 1;
  background-color: #6446f0;
  color: #ffffff;
  height: 34px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 15px;
  font-family: Roboto-Medium, Arial;
  margin-bottom: 16px;
}

.number {
  width: 35px;
  height: 38px;
  background-color: #ffffff;
  color: #2a93c3;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dee2e6;
  user-select: none;
  margin-left: -1px;
  margin-right: -1px;
}

.number:hover {
  background-color: #eee;
  color: #5a5a5a;
  cursor: pointer;
}

.pagination {
  display: flex;
  list-style: none;
  margin-left: 8px;
  margin-bottom: 48px;
  margin-top: 24px;
}

.pagination li {
  background-color: #ffffff;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  font-family: Roboto-Bold, Arial;
  font-size: 12px;
  margin-right: 8px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.pagination li a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination .disabled {
  background-color: #e8e8e8;
  cursor: default;
  display: none;
}

.pagination .selected {
  background-color: #1d59c7;
  color: #ffffff;
}

.selector {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-size: 15px;
  font-family: Roboto, Arial;
  min-height: 50px;
  padding: 16px;
  padding-left: 16px;
  border-radius: 4px;
  width: 100%;
  min-width: 100%;
  border: none;
  outline: none;
  box-sizing: border-box;
  background-color: #ffffff;
  border-right: 8px solid transparent;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 9L12 16L5 9" stroke="%239CA3AF" stroke-width="2"/></svg>');
  background-repeat: no-repeat, repeat;
  background-position: right 0.44em top 50%;
  background-size: 1.22em auto;
}

.searchField {
  width: 100%;
  position: relative;
  height: 50px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 16px;
}

.floating-label {
  box-sizing: border-box;
  display: block;
  width: 100%;
  position: relative;
  border-radius: 4px;
  min-height: 50px;
  overflow: hidden;
}

.floating-label input {
  width: 100%;
  border: none;
  box-sizing: border-box;
  font-size: 15px;
  color: #000000;
  font-family: Roboto, Arial;
  padding-left: 16px;
  padding-right: 16px;
  height: 50px;
  padding-top: 18px;
  border-radius: 4px;
  min-width: 100%;
  max-width: 100%;
}

.floating-label input:focus,
.floating-label textarea:focus {
  border-color: none;
  outline: none;
}

.floating-label input:focus + span,
.floating-label textarea:focus + span,
.floating-label.floating span {
  font-size: 12px;
  padding: 0;
  padding-top: 6px;
}

.floating-label input:focus:not(:focus-visible),
.floating-label textarea:focus:not(:focus-visible) {
  outline: none;
}

.floating-label span {
  box-sizing: border-box;
  left: 16px;
  padding: 14px 0 13px 0;
  pointer-events: none;
  position: absolute;
  top: 2px;
  transition: font-size 200ms, padding 200ms;
  font-size: 15px;
  color: #828282;
  font-family: Roboto, Arial;
}

.searchIcon {
  cursor: pointer;
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  width: 52px;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.uploadContainer {
  position: relative;
  margin-top: -45px;
}

.uploaderOverwrite {
  position: absolute;
  height: 45px;
  width: 100%;
  background-color: #f4f7f7;
}

.linkBox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

#BridApiIframeUpload {
  width: 100%;
}
